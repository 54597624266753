import React from 'react'
import { CreateAppBtn } from '../../../features/applications/components/create-app-btn'
import { ExportToXLSBtn } from '../../../features/applications/components/exporttoxls-btn/exporttoxls-btn.component'
import { Box } from '@material-ui/core'
import { AutoassignBtn } from '../../../features/applications/components/autoassign-btn'
import { MoreActionsBtn } from '../../../features/applications/components/more-actions-btn'
import { APP_EDITION_OPTIONS } from '../../../features/application-detail/constants/detail.constants'
import {
  APPS_ALERT_FIELDS,
  APPS_TABLE_COLUMNS,
  BASIC_COLUMN_LIST,
  E_COMMERCE_COLUMN_LIST,
  REGULAR_COLOR,
  WARNING_COLOR,
} from '../../../features/applications/constants'
import { FinishConnecProcessBtnComponent } from '../../../features/applications/components/finish-connec-process-btn'

const URL_GESTORES = '/solicitudes/tabla_gestores/'
const URL_CALL_CENTERS = '/solicitudes/tabla_tipo_perfil'
const URL_E_COMMERCE = '/solicitudes/tabla_gestor_ecommerce'

export const APPS_COMBOS_IDS = [
  'tipo_presupuesto',
  'motivos_anulacion_gestor',
  'motivos_anulacion_ecommerce',
  'cod_zona_zeus',
  'tipo_mercado',
  'uso_punto_suministro',
  'iri',
  'tipo_gas',
  'distribuidora',
  'ubicacion_contador',
  'rangos_potencia',
  'rangos_consumo_todos',
  'propiedad_contador',
  'propiedad_irc_armario_regulacion',
  'caso_caldera_centralizada',
  'tarifa_aplicada',
  'tipo_vivienda',
  'tipo_vivienda_gestor',
  'tipo_mercado_gestor',
  'productos_instalacion',
  'empresas',
  'gremios',
]

const ButtonsCallCenterAndGestorGC = (choosesSelectraProfile = false) => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <CreateAppBtn {...{ choosesSelectraProfile }} />
    </Box>
  )
}

const ButtonsSelectra = (choosesSelectraProfile = false) => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <ExportToXLSBtn />
      <CreateAppBtn {...{ choosesSelectraProfile }} />
    </Box>
  )
}

const ButtonsGss_vt = (choosesSelectraProfile = false) => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <ExportToXLSBtn />
      <CreateAppBtn {...{ choosesSelectraProfile }} />
    </Box>
  )
}

const ButtonsAccom = (choosesSelectraProfile = false) => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <ExportToXLSBtn />
      <CreateAppBtn {...{ choosesSelectraProfile }} />
    </Box>
  )
}

const ButtonsECommerce = () => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <ExportToXLSBtn />
    </Box>
  )
}

const ButtonsGestor = () => {
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <FinishConnecProcessBtnComponent />
      <AutoassignBtn />
      <MoreActionsBtn />
    </Box>
  )
}

export const USER_APP_CONFIG = ({ rol }) => {
  //Default:
  const config = {
    combos: [],
    customCombos: [],
    filters: [],
    columns: [],
    columns_export: [],
    appUrl: '',
    headerButtons: null,
    tableOptions: {},
    goesToDetail: false,
    seesDocumentPanel: false,
    canEditApp: APP_EDITION_OPTIONS.none,
    canEditOwnClients: false,
    seesRelatedApps: false,
    commentsOnLog: false,
    followClient: false,
    advancesPetition: false,
  }

  //General characteristics
  let comboEstadoSolicitud = 'estados_solicitud'
  switch (rol) {
    case 'call_center':
    case 'selectra':
    case 'gss_vt':
    case 'eurofirms':
    case 'accom':
    case 'gestor_gc':
    case 'soporte_procesos':
      comboEstadoSolicitud += '_call_center'
      break
    case 'delegado':
    case 'gestor':
    case 'e_commerce':
      comboEstadoSolicitud += '_gestores'
      break
    default:
      return { ...config, loading: true }
  }
  config['combos'] = [...APPS_COMBOS_IDS, comboEstadoSolicitud, comboEstadoSolicitud + '_filtro']
  config['filters'] = [
    {
      name: 'creada_desde',
      filterType: 'date',
      maxDateField: 'creada_hasta',
    },
    {
      name: 'creada_hasta',
      filterType: 'date',
      minDateField: 'creada_desde',
    },
    {
      name: 'solicitud_estado',
      label: 'Estado sol.',
      comboId: comboEstadoSolicitud + '_filtro',
      size: 'small',
      multiple: true,
    },
  ]
  config['columns'] = [...BASIC_COLUMN_LIST]

  //Specifics
  switch (rol) {
    //-------------- CALL_CENTER
    case 'call_center':
      config['appUrl'] = URL_CALL_CENTERS
      config['headerButtons'] = () => ButtonsCallCenterAndGestorGC(false)
      config['tableOptions'] = { fixedColumns: { right: 0 }, selection: false }
      config['goesToDetail'] = true
      config['canEditOwnClients'] = true
      config['canEditApp'] = APP_EDITION_OPTIONS.all
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp', 'origen_solicitud']
      config['customCombos'] = ['empresas', 'gremios', 'alerts']
      config['filters'] = [
        ...config['filters'],
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'municipio',
          filterType: 'basic-input',
          size: 'small',
        },
        {
          name: 'empresa',
          comboId: 'empresas',
          size: 'small',
          filterType: 'autocomplete',
        },
        {
          name: 'gremio',
          comboId: 'gremios',
          size: 'small',
          filterType: 'autocomplete',
        },
        {
          name: 'tipo_solicitud',
          comboId: 'origen_solicitud',
          size: 'small',
          multiple: true,
        },
      ]
      config['columns'] = ['comms', ...config['columns'], 'instalador', 'asociacion']
      config['columns'].unshift('pendiente_peticionario')
      config['columns'].splice(5, 0, 'origen')
      break

    //-------------- SELECTRA
    case 'selectra':
      config['appUrl'] = URL_CALL_CENTERS
      config['headerButtons'] = () => ButtonsSelectra(true)
      config['tableOptions'] = { fixedColumns: { right: 0 } }
      config['goesToDetail'] = true
      //Extensions:
      config['combos'] = [...config['combos'], 'origen_solicitud', 'call_center_sub_origins']
      config['filters'] = [
        ...config['filters'],
        {
          name: 'tipo_solicitud',
          comboId: 'origen_solicitud',
          size: 'small',
          multiple: true,
        },
      ]
      config['columns'].unshift('pendiente_peticionario')
      config['columns'].splice(5, 0, 'origen')
      config['columns'].push('empresa_instaladora', 'empresa_instaladora_telefono')
      break
    //---------------Accom
    case 'accom':
      config['appUrl'] = URL_CALL_CENTERS
      config['headerButtons'] = () => ButtonsAccom(false)
      config['tableOptions'] = { fixedColumns: { right: 0 } }
      config['goesToDetail'] = true
      //Extensions:
      config['combos'] = [...config['combos'], 'origen_solicitud', 'call_center_sub_origins']
      config['filters'] = [...config['filters']]
      config['columns'].unshift('pendiente_peticionario')
      config['columns'].splice(5, 0, 'origen')
      config['columns'].push('empresa_instaladora', 'empresa_instaladora_telefono')
      break
    //---------------GSS_VT
    case 'gss_vt':
    case 'eurofirms':
      config['appUrl'] = URL_CALL_CENTERS
      config['headerButtons'] = () => ButtonsGss_vt(false)
      config['tableOptions'] = { fixedColumns: { right: 0 } }
      config['goesToDetail'] = true
      //Extensions:
      config['combos'] = [...config['combos'], 'origen_solicitud']
      config['filters'] = [...config['filters']]
      config['columns'].unshift('pendiente_peticionario')
      config['columns'].splice(5, 0, 'origen')
      config['columns'].push('empresa_instaladora', 'empresa_instaladora_telefono')
      break
    //-------------- GESTOR
    case 'gestor':
      config['appUrl'] = URL_GESTORES
      config['headerButtons'] = () => ButtonsGestor()
      config['tableOptions'] = { fixedColumns: { right: 1 } }
      config['seesDocumentPanel'] = true
      config['canEditApp'] = APP_EDITION_OPTIONS.own
      config['canEditOwnClients'] = true
      config['advancesPetition'] = true
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp']
      config['columns'] = ['pendiente_peticionario', 'comms', ...config['columns'], 'actions']
      config['columns'].splice(
        config['columns'].indexOf('presupuestos_fecha_solicitud'),
        0,
        'fecha_solicitud'
      )
      config['filters'] = [
        ...config['filters'],
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'municipio',
          filterType: 'basic-input',
          size: 'small',
        },
        {
          name: 'comunicaciones_pendientes',
          filterType: 'switch',
          cleanSelf: true,
        },
        {
          name: 'responsable',
          comboId: 'gestores',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_gas',
          comboId: 'tipo_gas',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_mercado',
          comboId: 'tipo_mercado_gestor',
          size: 'small',
          multiple: true,
        },
        {
          name: 'l4',
          filterType: 'switch',
          cleanSelf: true,
        },

        {
          name: 'tipo_vivienda',
          comboId: 'tipo_vivienda_gestor',
          size: 'small',
          multiple: true,
        },
      ]
      config['customCombos'] = ['gestores']
      break

    //-------------- DELEGADO
    case 'delegado':
      config['appUrl'] = URL_GESTORES
      config['headerButtons'] = () => ButtonsGestor()
      config['tableOptions'] = { fixedColumns: { right: 1 } }
      config['seesDocumentPanel'] = true
      config['canEditApp'] = APP_EDITION_OPTIONS.all
      config['advancesPetition'] = true
      config['canEditOwnClients'] = true
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp']
      config['columns'] = ['pendiente_peticionario', 'comms', ...config['columns'], 'actions']
      config['columns'].splice(
        config['columns'].indexOf('presupuestos_fecha_solicitud'),
        0,
        'fecha_solicitud'
      )
      config['filters'] = [
        ...config['filters'],
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'municipio',
          filterType: 'basic-input',
          size: 'small',
        },
        {
          name: 'comunicaciones_pendientes',
          filterType: 'switch',
          cleanSelf: true,
        },
        {
          name: 'responsable',
          comboId: 'gestores',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_gas',
          comboId: 'tipo_gas',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_mercado',
          comboId: 'tipo_mercado_gestor',
          size: 'small',
          multiple: true,
        },
        {
          name: 'l4',
          filterType: 'switch',
          cleanSelf: true,
        },
        {
          name: 'tipo_vivienda',
          comboId: 'tipo_vivienda_gestor',
          size: 'small',
          multiple: true,
        },
      ]
      config['customCombos'] = ['gestores']
      break

    //---------------GESTOR_GC
    case 'gestor_gc':
      config['appUrl'] = URL_GESTORES
      config['headerButtons'] = () => ButtonsCallCenterAndGestorGC(false)
      config['tableOptions'] = { fixedColumns: { right: 0 }, selection: false }
      config['goesToDetail'] = true
      config['canEditOwnClients'] = false
      config['canEditApp'] = APP_EDITION_OPTIONS.none
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp']
      config['columns'] = ['pendiente_peticionario', 'comms', ...config['columns']]
      config['columns'].splice(
        config['columns'].indexOf('presupuestos_fecha_solicitud'),
        0,
        'fecha_solicitud'
      )
      config['filters'] = [
        ...config['filters'],
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'municipio',
          filterType: 'basic-input',
          size: 'small',
        },
        {
          name: 'comunicaciones_pendientes',
          filterType: 'switch',
          cleanSelf: true,
        },
        {
          name: 'responsable',
          comboId: 'gestores',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_gas',
          comboId: 'tipo_gas',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_mercado',
          comboId: 'tipo_mercado_gestor',
          size: 'small',
          multiple: true,
        },
        {
          name: 'l4',
          filterType: 'switch',
          cleanSelf: true,
        },
        {
          name: 'tipo_vivienda',
          comboId: 'tipo_vivienda_gestor',
          size: 'small',
          multiple: true,
        },
      ]
      config['customCombos'] = ['gestores']
      break

    //-------------- E_COMMERCE
    case 'e_commerce':
      config['appUrl'] = URL_E_COMMERCE
      config['headerButtons'] = () => ButtonsECommerce()
      config['tableOptions'] = {
        fixedColumns: { right: 0 },
        rowStyle: (rowData) => ({
          backgroundColor: APPS_ALERT_FIELDS.find((el) => rowData[el])
            ? WARNING_COLOR
            : REGULAR_COLOR,
        }),
      }
      config['goesToDetail'] = true
      config['canEditApp'] = APP_EDITION_OPTIONS.all
      config['seesRelatedApps'] = true
      config['commentsOnLog'] = true
      config['followClient'] = true
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp', 'origen_solicitud']
      config['customCombos'] = ['empresas', 'gremios', 'alerts']
      config['filters'] = [
        ...config['filters'],
        {
          name: 'mercado',
          comboId: 'tipo_mercado',
          size: 'small',
          multiple: true,
        },
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'alertas',
          comboId: 'alerts',
          size: 'small',
          multiple: true,
        },
        {
          name: 'tipo_solicitud',
          comboId: 'origen_solicitud',
          size: 'small',
          multiple: true,
        },
        {
          name: 'empresa',
          comboId: 'empresas',
          size: 'small',
          filterType: 'autocomplete',
        },
        {
          name: 'gremio',
          comboId: 'gremios',
          size: 'small',
          filterType: 'autocomplete',
        },
      ]
      config['columns'] = ['comms', 'rating', ...E_COMMERCE_COLUMN_LIST]
      break
    case 'soporte_procesos':
      config['appUrl'] = URL_CALL_CENTERS
      config['headerButtons'] = () => {}
      config['tableOptions'] = { fixedColumns: { right: 0 }, selection: false }
      config['goesToDetail'] = true
      config['canEditOwnClients'] = false
      config['canEditApp'] = APP_EDITION_OPTIONS.none
      //Extensions
      config['combos'] = [...config['combos'], 'provincias_by_cp', 'origen_solicitud']
      config['customCombos'] = ['empresas', 'gremios', 'alerts']
      config['filters'] = [
        ...config['filters'],
        {
          name: 'provincia',
          comboId: 'provincias_by_cp',
          size: 'small',
          multiple: true,
        },
        {
          name: 'municipio',
          filterType: 'basic-input',
          size: 'small',
        },
        {
          name: 'empresa',
          comboId: 'empresas',
          size: 'small',
          filterType: 'autocomplete',
        },
        {
          name: 'gremio',
          comboId: 'gremios',
          size: 'small',
          filterType: 'autocomplete',
        },
        {
          name: 'tipo_solicitud',
          comboId: 'origen_solicitud',
          size: 'small',
          multiple: true,
        },
      ]
      config['columns'] = [...config['columns'], 'instalador', 'asociacion']
      config['columns'].splice(5, 0, 'origen')
      break
    default:
      console.debug('Error: role is not defined')
  }
  config['columns'] = config['columns'].map((col) => APPS_TABLE_COLUMNS(comboEstadoSolicitud)[col])
  config['columns_export'] = config['columns'].filter(
    (column) =>
      -1 ===
      [
        'actions',
        'valoraciones_pendientes',
        'comunicaciones_pendientes',
        'usuario_identificador',
        'presupuestos_fecha_realizacion',
      ].indexOf(column.field)
  )
  return config
}
